<template>
    <nav class="socials">
        <a
            v-for="social in socials"
            :key="social.name"
            class="socials__social"
            :href="social.url"
            target="blank"
            rel="noopener"
            :aria-label="`Go to our ${social.name} page`"
        >
            <BaseIcon
                :icon="social.icon"
                :auto-color="false"
            />
        </a>
    </nav>
</template>

<script setup>
const socials = [
    { name: 'LinkedIn', icon: 'linkedin', url: 'https://www.linkedin.com/company/european-association-of-urology/' },
    { name: 'Facebook', icon: 'facebook', url: 'https://www.facebook.com/EAUpage' },
    { name: 'Twitter', icon: 'twitter', url: 'https://twitter.com/Uroweb' },
    { name: 'Instagram', icon: 'instagram', url: 'https://www.instagram.com/uroweb/' },
    { name: 'YouTube', icon: 'youtube', url: 'https://www.youtube.com/c/uroweb' }
];
</script>

<style lang="less">
.socials {
    display: flex;
}

.socials__social {
    color: var(--color-text-dark);
    font-size: var(--font-size-2xl);

    & + .socials__social {
        margin-left: 1.25rem;
    }
}
</style>
