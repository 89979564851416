<template>
    <DelayHydration>
        <footer
            id="footer-landmark"
            class="footer layout__footer"
        >
            <section class="footer__section footer__navigation">
                <TheFooterList
                    v-for="(list, index) in footerLists"
                    :key="index"
                    class="footer__list"
                    :items="list.items"
                    :title="list.title"
                />

                <BaseAccordion
                    class="footer__accordion"
                    :items="footerLists"
                >
                    <template #default="{ item }">
                        <TheFooterLink
                            v-for="(link, linkIndex) in item.items"
                            :key="linkIndex"
                            :item="link"
                        />
                    </template>
                </BaseAccordion>
            </section>
            <section class="footer__section footer__logos">
                <img
                    src="~/assets/img/logos/eau-full.svg"
                    alt="European Association of Urology"
                    width="429"
                    height="42"
                >

                <BaseSocials class="footer__socials" />
            </section>
            <section class="footer__section footer__legal">
                <nuxt-link to="/privacy-policy">Privacy Policy</nuxt-link>
                <nuxt-link to="/disclaimer">Disclaimer</nuxt-link>
            </section>
        </footer>
    </DelayHydration>
</template>

<script setup>
import FooterQuery from '~/graphql/queries/global/Footer.graphql';

const { data } = await useLazyAsyncQuery({
    query: FooterQuery,
});

const footerData = computed(() => data?.value?.footer?.footer ?? []);

const footerLists = ref([{
    title: 'Contact',
    items: [
        { text: 'EAU Central Office', type: 'text' },
        { text: 'PO Box 30016', type: 'text' },
        { text: 'NL-6803 AA ARNHEM', type: 'text' },
        { text: 'The Netherlands', type: 'text' },
        { text: 'Contact us', type: 'button', uri: '/contact' },
    ]
}]);

watchEffect(() => {
    if (footerData.value) {
        const lists = [];

        footerData.value.forEach((column) => {
            lists.push({
                title: column.blockTitle,
                items: getItems.call(this, column)
            });
        });

        // Prepend lists to existing (hardcoded comes last)
        footerLists.value.unshift(...lists);
    }
});

function getItems(column) {
    const items = [];

    column.children?.[0]?.lists?.forEach((item) => {
        const uri = getItemLink(item);
        items.push({
            text: item.linkTitle,
            type: uri ? 'link' : 'text',
            uri
        });
    });

    return items;
}

function getItemLink(item) {
    return item.linkInternal?.length ? `/${item.linkInternal[0].uri}` : item.linkExternal;
}
</script>

<style lang="less" src="./TheFooter.less" />
