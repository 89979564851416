<template>
    <div
        class="layout"
        :data-menu-open="menuIsOpen"
    >
        <NuxtLoadingIndicator color="#FE6B2E" />

        <section class="layout__header">
            <TheNavigation
                :disable-login="disableLogin"
                @menu-toggled="onMenuToggle"
            />
        </section>

        <BaseNotice
            v-if="disableLogin && noticeETA"
            :message="
                `The login system is currently undergoing maintenance and is not available.${noticeETA ? ' It will be back online on ' + noticeETA + '.' : ''}`
            "
            :readmore-link="noticeLink"
        />

        <!--        <client-only>-->
        <!--            <dn-cookiebar />-->
        <!--        </client-only>-->
        <slot />

        <section class="layout__footer">
            <TheFooter />
        </section>
    </div>
</template>

<script setup>
const disableLogin = ref(null);
const noticeETA = ref(null);
const noticeLink = ref('');
const menuIsOpen = ref(null);

function onMenuToggle(state) {
    menuIsOpen.value = state;
}

onMounted(() => {
    navigator.serviceWorker.getRegistrations().then(async(registrations) => { // fixme: temp fix unregister invalid service workers
        if (registrations.length) {
            for (const registration of registrations) {
                await registration.unregister();
            }
            window.location.reload();
        }
    });
});

/**
 *     components: {
        // DnCookiebar,
        UroNavigation,
        UroFooter,
        UroNotice
    },

    data() {
        return {
            menuIsOpen: null,
            disableLogin: null,
            noticeETA: null,
            noticeLink: ''
        };
    },

    async fetch() {
        const response = await this.$gql.executeQuery(loginQuery);
        if (response && response.data.login) {
            this.disableLogin = response.data.login.toggle;

            if (response.data.login.date) {
                this.noticeETA = formatSingleDate(response.data.login.date, formats.dayMonth);
            }

            if (response.data.login.entry) {
                this.noticeLink = response.data.login.entry[0]?.uri;
            }
        }
    },

    methods: {
        onMenuToggle(state) {
            this.menuIsOpen = state;
        }
    }
 */
</script>

<style lang="less">
.layout {
    display: grid;
    grid-template-areas: 'header' 'notice' 'main' 'footer';
    grid-template-rows: auto auto 1fr auto;
    min-height: 100vh;
    gap: 0;
    margin: 0 auto;
    background: var(--color-lightest);
}

.layout[data-menu-open=true] {
    &:before {
        content: '';
        display: block;
        position: fixed;
        z-index: 10;
        background: var(--color-black-30);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    @media @q-xl-min {
        overflow: auto;

        &:before {
            content: none;
            display: none;
        }
    }
}

.layout__header {
    grid-area: header;
    position: sticky;
    top: 0;
    z-index: 100;
}

.layout__main {
    .grid-container();
    .vertical-padding();

    grid-area: main;
    justify-self: center;
}

.layout__main--narrow {
    .grid-container(true, var(--grid-maxWidth-content));
}

.layout__main--centered {
    align-content: center;
}

.layout__main--no-top-padding {
    padding-top: 0;

    .error:first-child {
        .vertical-padding();
    }
}

.layout__footer {
    grid-area: footer;
}
</style>
