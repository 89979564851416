<template>
    <header
        class="uro-navigation"
        :data-sticky="scrollTop > 0"
    >
        <div class="uro-navigation__container">
            <NuxtLink
                class="uro-navigation__logo"
                aria-label="Go to home"
                to="/"
                @click="menuToggled = false"
            >
                <img
                    alt="Uroweb Logo"
                    src="~/assets/img/logos/eau.svg"
                    width="82"
                    height="33"
                >
            </NuxtLink>

            <nav
                v-if="!isPending && navigationLinks"
                class="uro-navigation__menu"
                :data-toggled="menuToggled"
            >
                <template v-for="(item, index) in navigationLinks">
                    <template v-if="item.hasSubmenu">
                        <TheNavigationLink
                            :key="index"
                            :title="menuTitles[index]"
                            :submenu="{ primaryMenu: item.primaryMenu, secondaryMenu: item.secondaryMenu }"
                            @close-menu="menuToggled = false"
                        />
                    </template>
                    <template v-else>
                        <TheNavigationLink
                            :key="index"
                            :title="menuTitles[index]"
                            :to="getItemLink(item)"
                            @click="menuToggled = false"
                        />
                    </template>
                </template>

                <ClientOnly>
                    <footer class="uro-navigation__menu__footer">
                        <template v-if="status !== 'authenticated'">
                            <BaseButton
                                v-if="!disableLogin"
                                target="_self"
                                element="a"
                                class="dn-button--label"
                                @click="() => {
                                    menuToggled = false;
                                    signIn('uroweb');
                                }"
                            >
                                Log in
                            </BaseButton>
                            <BaseButton
                                element="nuxt-link"
                                to="/about/why-join-the-eau"
                                class="dn-button--label"
                                @click="menuToggled = false"
                            >
                                Become member
                            </BaseButton>
                        </template>
                        <template v-else>
                            <BaseButton
                                to="/profile"
                                :title="`You are logged in ${user?.name ? `as ${user.name}` : ''}`"
                                class="uro-navigation__user"
                                aria-label="Go to your profile"
                                @click="menuToggled = false"
                            >
                                <BaseIcon icon="person" />{{ user?.firstName }}
                            </BaseButton>
                        </template>
                        <BaseSocials />
                    </footer>
                </ClientOnly>
            </nav>

            <div class="uro-navigation__actions">
                <TheSearchForm :in-navigation="true" />

                <ClientOnly>
                    <div
                        v-if="status !== 'authenticated'"
                        class="uro-navigation__login"
                    >
                        <a
                            v-if="!disableLogin"
                            target="_self"
                            class="navigation-link"
                            @click="() => {
                                menuToggled = false;
                                signIn('uroweb');
                            }"
                        >
                            Log in
                        </a>

                        <BaseButton
                            :element="NuxtLink"
                            to="/about/why-join-the-eau"
                            class="base-button"
                            @click="menuToggled = false"
                        >
                            Become member
                        </BaseButton>
                    </div>
                    <NuxtLink
                        v-else
                        to="/profile"
                        :title="`You are logged in ${user?.name ? `as ${user.name}` : ''}`"
                        class="uro-navigation__user"
                        aria-label="Go to your profile"
                        @click="menuToggled = false"
                    >
                        <BaseIcon icon="person" />
                    </NuxtLink>
                </ClientOnly>

                <TheHamburgerMenu
                    :data-toggled="menuToggled"
                    @toggle-menu="menuToggled = !menuToggled"
                />
            </div>
        </div>
    </header>
</template>

<script setup>
import MainNavigationQuery from '~/graphql/queries/global/MainNavigation.graphql'; // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars

const {data: {user}, status, signIn} = useAuth();

const NuxtLink = resolveComponent('NuxtLink');

const { data, isPending } = await useLazyAsyncQuery({
    query: MainNavigationQuery,
});

const navigationLinks = ref({});
const menuTitles = ref({
    guidelines: 'Guidelines',
    education: 'Education & Events',
    scientific: 'Science & Publications',
    about: 'About'
});

watchEffect(() => {
    if (data.value) {
        navigationLinks.value = data.value;
    }
});

const emit = defineEmits(['menuToggled']);
const menuToggled = ref(false);
watch(() => menuToggled.value, (state) => {
    emit('menuToggled', state);
});

const route = useRoute();
watch(() => route.path, () => {
    menuToggled.value = false;
});

/**

 // todo - Do these need to be implemented?

     mounted() {
        document.getElementsByClassName('layout')[0].addEventListener('click', this.hideMenu, true);
        document.addEventListener('keydown', this.listenForEscape);
    },

    beforeDestroy() {
        document.getElementsByClassName('layout')[0].removeEventListener('click', this.hideMenu, true);
        document.removeEventListener('keydown', this.listenForEscape);
    },

 *         listenForEscape(e) {
            if (e.key === 'Escape') {
                this.menuToggled = false;
            }
        },

        hideMenu(e) {
            if (e?.target && e.target.className === 'layout') {
                this.menuToggled = false;
            }
        },
 */

const scrollTop = ref(0);
const disableLogin = ref(null);

function getItemLink(item) {
    if (item.link?.length) {
        if (item.link[0].uri === '__home__') {
            return '/';
        } else {
            return '/' + item.link[0].uri;
        }
    }

    return '';
}
</script>

<style lang="less" src="./TheNavigation.less"></style>
