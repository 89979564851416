
<template>
    <component
        :is="element"
        ref="target"
        v-bind="$attrs"
        class="navigation-link"
        :class="{ 'navigation-link--subitem': isSub }"
        :data-has-icon="!!icon"
        :data-has-submenu="!!submenu"
        :data-submenu-open="submenuVisible"
        @click="() => submenu ? toggleSubmenu() : undefined"
        @mouseenter="() => submenu ? toggleHover(true) : undefined"
        @mouseleave="() => submenu ? toggleHover(false) : undefined"
    >
        <template v-if="icon">
            <div class="navigation-link__icon">
                <BaseIcon
                    :icon="icon"
                    class="dn-icon--square"
                />
            </div>
        </template>

        <span class="navigation-link__title">
            <slot>
                {{ title }}
                <BaseIcon
                    v-if="isExternal"
                    icon="arrow-up-right"
                />
                <BaseIcon
                    v-if="submenu"
                    icon="chevron-down"
                />
            </slot>
        </span>

        <span
            v-if="subtitle || $slots.subtitle"
            class="navigation-link__subtitle"
        >
            <slot name="subtitle">
                {{ subtitle }}
            </slot>
        </span>

        <transition
            v-if="submenu"
            name="fade"
        >
            <TheNavigationSubmenu
                :is-visible="submenuVisible"
                :primary-items="submenu.primaryMenu"
                :secondary-items="submenu.secondaryMenu"
                @click="closeMenu"
            />
        </transition>
    </component>
</template>

<script setup>
import {computed, defineEmits, defineProps, ref, resolveComponent} from 'vue';
import { onClickOutside } from '@vueuse/core';

const props = defineProps({
    icon: {
        type: String,
        default: null
    },
    title: {
        type: String,
        default: null
    },
    subtitle: {
        type: String,
        default: null
    },
    submenu: {
        type: Object,
        default: null
    },
    isSub: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['closeMenu']);

const target = ref(null);

const { checkIsExternal } = useUri();

const attrs = useAttrs();

const isExternal = computed(() => {
    const destination = attrs.href || attrs.to;
    return checkIsExternal(destination);
});

const NuxtLink = resolveComponent('NuxtLink');

const element = computed(() => {
    return props.submenu ? 'div' : NuxtLink;
});

const submenuVisible = ref(false);

// Close submenu when clicking outside of the target element
onClickOutside(target, event => {
    if (submenuVisible.value) {
        if (event.target !== target.value) {
            submenuVisible.value = false;
        }
    }
});

const toggleSubmenu = () => {
    submenuVisible.value = !submenuVisible.value;
};

const toggleHover = (state) => {
    if (window.innerWidth > 1440) {
        submenuVisible.value = state;
    }
};

const closeMenu = () => {
    emit('closeMenu');
};
</script>

<style lang="less" src="./TheNavigationLink.less" />
